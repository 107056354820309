<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-3">
        <v-row class="justify-space-arround">
            <v-col cols="12" xl="10">
                <v-card class="default" outlined height="100%">
                    <EditorToolbar
                        :headline="model.translations.find(x => x.locale === currentLanguage).title"
                        :isModified="isModified"
                        :isWaiting="isWaiting"
                        :editMode="editMode"
                        @onSaveItem="$emit('onSaveItem')"
                        @onDeleteItem="$emit('onDeleteItem')"
                        @onCreateItem="$emit('onCreateItem')"
                        @onFindUsage="$emit('onFindUsage')"
                        :buttons="['new', 'save', 'delete', 'usageAndReplacement', 'translationState']"
                        />
                    <v-card-text class="pa-0">
                        <LeaTranslationTableInput v-model="model.translations" required autofocus @modified="$emit('modified', $event)" fieldValue="title" :fieldText="'label'" fieldType="text" />
                    </v-card-text>
                    <v-divider />
                    <v-card-text>
                        <v-text-field
                            v-model="model.alias"
                            :error-messages="errors.collect('alias')"
                            :label="$t('settings.domain.aliasField.fieldLabel')"
                            data-vv-name="alias"
                            v-validate="'required'"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-sheet class="d-inline-flex align-center pa-2">
                            <div>
                                <v-combobox
                                    v-model="model.icon"
                                    :items="iconItems"
                                    chips
                                    clearable
                                    :label="$t('settings.filter.icon')"
                                    @input="$emit('modified', $event)"
                                >
                                    <template v-slot:selection="data">
                                        <div
                                            :key="data.item"
                                            @click.stop="
                                                data.parent.selectedIndex = data.index
                                            "
                                            @input="data.parent.selectItem(data.item)"
                                        >
                                            <v-icon x-large :color="colorModel">{{ data.item }}</v-icon>
                                        </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-list-item-content>
                                            <v-icon x-large :color="colorModel">{{ item }}</v-icon>
                                        </v-list-item-content>
                                    </template>
                                </v-combobox>
                            </div>
                            <div class="ml-6">
                                <v-menu
                                    ref="colorMenu"
                                    v-model="colorMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            elevation="2"
                                            v-bind="attrs"
                                            v-on="on"
                                            icon
                                            :color="colorModel"
                                            ><v-icon>mdi-palette</v-icon></v-btn>
                                    </template>
                                        <v-color-picker
                                            v-model="colorModel"
                                            flat
                                            :swatches="colorSwatches"
                                            show-swatches
                                            hide-mode-switch
                                            dot-size="28"
                                            :width="$vuetify.breakpoint.smAndDown ? 300 : 600"
                                            @input="$emit('modified', $event)">
                                        </v-color-picker>
                                </v-menu>
                            </div>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isUUID } from '@/components/vvt/ModelMixin.js';
import EditorToolbar from '../EditorToolbar.vue';
import LeaTranslationTableInput from '@/components/Input/LeaTranslationTableInput';
import { icons } from '@/utils/iconHelper';

export default {
    name: 'SettingsDomainEditorMask',
    inject: {
        $validator: '$validator',
    },
    components: {
        EditorToolbar,
        LeaTranslationTableInput
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        availableLanguages: {
            type: Array,
            default: () => []
        },
        currentLanguage: {
            type: String,
            default: null
        },
        isWaiting: {
            type: Boolean,
            default: false
        },
        isModified: {
            type: Boolean,
            default: false
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    data() {
        return {
            model: this.value,
            iconItems: icons(),
            colorMenu: false,
            defaultColor: '#3073a3',
            colorSwatches: [
                ['#3F51B5', '#3073a3', '#2196F3', '#03A9F4', '#00BCD4'],
                ['#1B5E20', '#009688', '#4CAF50', '#8BC34A', '#CDDC39'],
                ['#B71C1C', '#F44336', '#FF5722', '#FFC107', '#FF9800'],
                ['#673AB7', '#9C27B0', '#E91E63', '#F8BBD0', '#E1BEE7'],
                ['#795548', '#BCAAA4', '#9E9E9E', '#607D8B', '#B0BEC5'],
            ],
        };
    },
    mounted() {
        if (isUUID(this.model.id)) {
            this.model.clientId = this.value.id;
        }
    },
    computed: {
        editMode() {
            return !isUUID(this.model.id);
        },
        iconModel: {
            get() {
                return this.value.icon;
            },
            set(val) {
                this.model.icon = val;
            },
        },
        colorModel: {
            get() {
                return this.value && this.value.color ? this.value.color : this.defaultColor;
            },
            set(val) {
                this.model.color = val;
            },
        },
    },
};
</script>
